button.back-button {
  width: 200px;
  border-radius: 1000px;
  background-color: #eaeef1;
  color: #0083d2;
  margin-bottom: 10px;
}

@import "./Responsive.scss";
@import "./Scrollbar.scss";
