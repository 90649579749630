/* maybe quit */
body {
  margin: 0;
}
.message {
  display: grid;
  gap: 16px;
}
.message p {
  margin: 0;
}
html {
  background-color: #f5f7f8;
}
.SideMenuLeft_root__brZbl,
.SideMenuLeft_root__w8ZMT {
  box-shadow: 2px 2px 20px 20px #6d8aa611;
}
.MuiBox-root.css-vgcmmp {
  margin: 5vh 6vw;
  width: 86vw;
  padding: 0;
}
.MuiBox-root.css-12gzogs,
.MuiBox-root.css-90jbqm {
  background-color: transparent;
  padding: 0;
  padding-left: 10px;
}
.css-120p53s {
  width: 25%;
}
/* td {
  width: 25%;
} */
.class-SearchFilterComponent > div:last-child > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 70px;
  text-align: center;
}

.class-child-admin {
  grid-template-columns: auto !important;
}

.class-child-profile {
  display: grid;
  grid-template-columns: auto 200px;
  gap: 20px;
}

.class-child-profile
  > div:first-child
  > p:nth-child(1)
  > div
  > div:nth-child(2)
  > div {
  gap: 5px;
  display: flex;
  justify-content: flex-start;
}

.class-child-profile
  > div:first-child
  > p:nth-child(3)
  > div
  > div:nth-child(2)
  > div
  > .css-1e8uqjm {
  width: 32.9%;
}

.class-child-profile .css-tq2x1p-MuiTableContainer-root {
  height: max-content;
}
button.class-edit-profile-button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: #0083d2;
  border-radius: 30px;
  gap: 10px;
}

a.class-back-button {
  text-decoration: none;
}

a.class-back-button > button {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

a.class-back-button > button > .css-9tj150-MuiButton-endIcon {
  display: inherit;
  margin-right: 0;
  margin-left: 0;
}
label.MuiFormLabel-root.MuiFormLabel-colorPrimary.css-1atai6e-MuiFormLabel-root {
  padding-left: 23px;
  padding-bottom: 10px;
}
span.MuiTypography-root.MuiTypography-caption.css-z0c2mh-MuiTypography-root {
  position: absolute;
}
.css-15ovows-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 0 !important;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.MuiAutocomplete-inputRoot.css-11tj6yu-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 8px 9px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-colorAction.MuiSvgIcon-fontSizeMedium.css-k35kgr-MuiSvgIcon-root {
  color: #0083d2;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-4.css-yaog9p-MuiGrid-root {
  display: grid;
}
input#google-map-demo {
  padding-left: 12px;
}
p#filled-hidden-label-small-helper-text {
  display: none;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.css-9ab9vi-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #eaeef1 !important;
  border: 1px solid #707070 !important;
  border-radius: 1000px !important;
  height: 53px !important;
  justify-content: center;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon.class-GoogleInput.css-16awh2u-MuiAutocomplete-root {
  display: grid;
}
.css-kpwbqx-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  background-color: #0083d2 !important;
  color: rgba(0, 0, 0, 0.26);
  opacity: 0.5;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.css-1osj8n2-MuiGrid-root {
  display: flex;
  justify-content: center;
  align-content: center;
}
.class-button-save-add-seat {
  display: flex;
  justify-content: end;
  align-self: end;
  height: auto;
}
/* maybe quit */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*css menu start*/
.class-menu-style {
  border-radius: 20px;
  padding: 10px;
  margin: 20px;
  margin-right: 0;
  box-shadow: 2px 2px 20px 10px #1f293733;
}

.class-menu-style > div:last-child {
  margin: -10px;
  border-radius: 0 0 20px 20px;
}

.class-menu-style > div:last-child > div > div > div:first-child img {
  background: white;
  border-radius: 1000%;
  padding: 5px;
}

span.my-1.px-4.py-2.rounded-full.bg-green-200.text-green-500.font-semibold.text-sm.flex.align-center.w-max.cursor-pointer.active\:bg-gray-300.transition.duration-300.ease {
  background: #374151;
  color: #d1d5db;
}
/*css menu end*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.PhoneInputInput {
  padding: 0.5em;
}
.animation-spin {
  animation-name: spin;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  90% {
    transform: rotate(350deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3s infinite, blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

/*responsive of widht 1400px*/
@media only screen and (max-width: 1400px) {
  .MuiBox-root.css-vgcmmp {
    margin: 5vh 2vw;
    width: 96vw;
  }
}

.class-CLIENT-SearchFilterComponent
  .class-FilterComponent
  > form
  > div:nth-child(3) {
  grid-column: 1;
  justify-content: flex-start;
}

.class-CLIENT-SearchFilterComponent
  .class-FilterComponent
  > form
  > div:nth-child(3)
  > div {
  width: 100%;
}

/* .class-CLIENT-SearchFilterComponent div.MuiAutocomplete-popper > div {
  width: 100%;
} */
